import React from 'react';
import { styled } from 'styled-components';
import PDFFile from '../pdf/Simone_Full_Stack_Developer_CV.pdf';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  .btn-wrapper {
      flex-direction: column;
      align-items: flex-end;
      cursor: pointer;
      position: relative;
      display: flex;
  }

  .btnn {
    position: relative
    /* Tell the browser what property is going to change. */
    /* cf https://aerotwist.com/blog/bye-bye-layer-hacks/ */
    will-change: transform, background-color;
    width: 10rem;
    height: 2.5rem; /* Use the specific height value here */
    border-radius: 2px;
    border: none;
    padding: 0;
    background-color: #023e8a; /* Use the specific color value here */
    color: white;
    transition: all ease-out 0.2s;
    transform-origin: bottom;
    outline: none;
    overflow: hidden;

    &:hover {
      background-color: #0077b6; /* Use the specific color value here */
    }

    /* This pseudo-element will be the btn loading bar */
    &::before {
      content: '';
      display: block;
      transition: transform 1s ease-out 2s;
      transform: scaleX(0);
    }
  }

  .btnn.is-active {
    transform: scaleY(0.1);
    background-color: #023e8a; /* Use the specific color value here */

    .btn__starter-text {
      opacity: 0;
    }

    &::before {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background-color: #0077b6; /* Use the specific color value here */
      transition: transform 1s ease-out;
      transform: scaleX(1);
      transform-origin: left;
      transition-delay: 0.2s;
    }
  }

  .btnn.dl-completed {
    transform: scale(0.5, 0.1);

    & + .btn__ender-text {
      opacity: 1;
      transform: none;
    }
  }

  /* Always reset the will-change property once the animation is finished */
  .btnn.animation-ended {
    will-change: auto;
  }
  .btn__text {
    display: block;
    width: 100%;
    text-align: center;
    line-height: $btn-height;
  }
  
  .btn__ender-text {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    opacity: 0;
    will-change: opacity;
    color: $black;
    transform: translateY(1rem);
    transition: all ease-out .3s 1s;
    transition-delay: .3s;
    pointer-events: none;
  }
    `;

const DownloadButton = () => {
  function download() {
    const btn = document.querySelector(".btnn"); // Use the correct class name here
    if (btn) {
      btn.classList.add('is-active');
      
      
      
      //const fileUrl = '../../public/Simone_Full_Stack_Developer_CV.pdf'; // Percorso relativo al file PDF
      
      /* fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        btn.classList.add('animation-ended');
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Curriculum_Simone_Aiello.pdf'; // Nome del file con estensione PDF
        link.click();
        window.URL.revokeObjectURL(url);
      }); */
      const link = document.createElement('a');
      link.href = PDFFile;
      link.download = 'Curriculum_Simone_Aiello.pdf';
      link.click();
    }
  }

  return (
    <Container>
      <div className="btn-wrapper">
        <button className="btnn" id="downloadBtn" onClick={download}>
          <span className="btn__text btn__starter-text">Download CV</span>
        </button>
        <span className="btn__text btn__ender-text">Downloaded</span>
      </div>
    </Container>
  );
}

export default DownloadButton;
