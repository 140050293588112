import React from 'react'
import { styled } from 'styled-components';
import {BrowserRouter} from 'react-router-dom';
import { HashLink as Link} from 'react-router-hash-link'

const Section = styled.div`
display: flex;
justify-content: center;
a{
  text-decoration:none;
  color: #fff;
}
@media only screen and (max-width: 768px) {
  width: 100%;
}
`;

const Container = styled.div`
  width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
`;

const Logo = styled.img`
  height: 50px;
`;

const List = styled.ul`
  display: flex;
  gap: 20px;
  list-style: none;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ListItem = styled.li`
  cursor: pointer;
`;


const Button = styled.button`
  width: 100px;
  padding: 10px;
  background-color: #023e8a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;


const Navbar = () => {
  return (
    <Section>
    <Container>
      <BrowserRouter>
      <Links>
        <Logo src="./img/c3ba124b-6228-4e9c-8fff-46d638bb8c85-transformed-removebg-preview.png" />
        <List>
          <ListItem>
            Home
          </ListItem>
          <ListItem>
          <Link to='#skills' smooth>
            Skill
            </Link>
          </ListItem>
          <ListItem>
          <Link to='#works' smooth>
          Works
            </Link>
            </ListItem>
          <ListItem>
          <Link to='#contact' smooth>
          Contact
            </Link>
            </ListItem>
        </List>
      </Links>
      </BrowserRouter>
      
        <Button>Hire Now</Button>

    </Container>
  </Section>
  )
}

export default Navbar