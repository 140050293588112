import React from 'react'
import Hero from './components/Hero'
import Skills from './components/Skills'
import Works from './components/Works'
import Contact from './components/Contact'
import styled from 'styled-components';



const Container = styled.div`
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  color: white;
  background: url("./img/bg-img.jpg");
  background-repeat: no repeat;
  background-size: cover;
  &::-webkit-scrollbar{
    display: none;
  }
`;
const App = () => {
  return (
    <Container>

      <Hero/>
      <Skills/>
      <Works/>
      <Contact/>
      
    </Container>
  )
}

export default App