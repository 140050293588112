import React from 'react'
import { styled } from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-cards';
import '../index.css';

const Section = styled.div`
  height: 100vh;
 /*  background-color:rebeccapurple; */
  scroll-snap-align: center;
  overflow-x: hidden;
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items:center;
  overflow-x: hidden;
  //gap: 50px;
`;
const Works = () => {
  const projects = [
    {
id: 1,
      title:'Benchmark Epicode Clone',
img: 'https://baasbox.com/wp-content/uploads/2022/10/EPICODE_cover-2-1024x454.png',
description: 'This repository is a clone of the well-known Epicode benchmark. It has been created for learning and demonstration purposes and is not affiliated with Epicode or its creators.',
link1: 'https://github.com/SimoneAiello97/BenchMark-Epicode-clone'
  },
  {
    id: 2,
    title:'Spotify-clone',
    img: 'https://storage.googleapis.com/pr-newsroom-wp/1/2018/11/folder_920_201707260845-1.png',
    description: 'This repository contains a clone of the popular music streaming service Spotify. It was developed during the Epicode course using JavaScript, Bootstrap, and Sass, with data provided by the Epicode API for songs and artists.',
    link1: 'https://github.com/SimoneAiello97/Spotify-clone',

      },
      {
        id: 3,
        title:'Netflix-clone',
        img: 'https://images.ctfassets.net/y2ske730sjqp/1aONibCke6niZhgPxuiilC/2c401b05a07288746ddf3bd3943fbc76/BrandAssets_Logos_01-Wordmark.jpg?w=940',
        description: 'This repository contains a clone of the popular streaming service Netflix. It was developed using CSS and Bootstrap to recreate the user interface of the Netflix website.',
        link1: 'https://github.com/SimoneAiello97/Netflix-clone',
    
          },
          {
            id: 4,
            title:'Medium-clone',
            img: 'https://miro.medium.com/v2/resize:fit:1200/1*jfdwtvU6V6g99q3G7gq7dQ.png',
            description: 'This repository contains a clone of the well-known publishing platform Medium. It was developed using CSS and Bootstrap to recreate the user interface and functionality of the Medium website.',
            link1: 'https://github.com/SimoneAiello97/Medium-clone',
        
              },{
                id: 5,
                title:'To Do List',
                img: 'https://i.pinimg.com/originals/19/ec/3b/19ec3ba003f7fb9fe93ce3a7f11aeba2.jpg',
                description: 'This repository contains a simple To-Do List application developed using Angular. It includes a mock backend for managing tasks.',
                link1: 'https://github.com/SimoneAiello97/ToDo-List',
            
                  },{
                    id: 6,
                    title:'Pizzeria-clone',
                    img: 'https://img.freepik.com/free-vector/pizza-create-decorative-icons-set-with-round-pizza-image_1284-15373.jpg',
                    description: 'This repository contains a web application for a pizzeria with user authentication and the ability to create and share pizza recipes.',
                    link1: 'https://github.com/SimoneAiello97/Pizzeria-clone',
                
                      },
  {
    id: 7,
    title:'E-Store',
    img: 'https://private-user-images.githubusercontent.com/126870680/271639910-3e1cbcd9-a2d5-44ce-8251-8028104efd99.png?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTEiLCJleHAiOjE2OTYyNjAxMjYsIm5iZiI6MTY5NjI1OTgyNiwicGF0aCI6Ii8xMjY4NzA2ODAvMjcxNjM5OTEwLTNlMWNiY2Q5LWEyZDUtNDRjZS04MjUxLTgwMjgxMDRlZmQ5OS5wbmc_WC1BbXotQWxnb3JpdGhtPUFXUzQtSE1BQy1TSEEyNTYmWC1BbXotQ3JlZGVudGlhbD1BS0lBSVdOSllBWDRDU1ZFSDUzQSUyRjIwMjMxMDAyJTJGdXMtZWFzdC0xJTJGczMlMkZhd3M0X3JlcXVlc3QmWC1BbXotRGF0ZT0yMDIzMTAwMlQxNTE3MDZaJlgtQW16LUV4cGlyZXM9MzAwJlgtQW16LVNpZ25hdHVyZT1kNTdhNWYxZDkxZDVmMjdmMDkwMzMzOTRiNDgwZTIxM2Y0MzQ0ZDRhNGYwMDA2YjcyMzRjYWQ0MWRmMjk3YjVkJlgtQW16LVNpZ25lZEhlYWRlcnM9aG9zdCZhY3Rvcl9pZD0wJmtleV9pZD0wJnJlcG9faWQ9MCJ9.0FYqZrm8vbnvqyAwKz-URWArVipD_YSgPu1CW8hee-I',
    description: 'This is a full-stack E-commerce application that includes both an admin dashboard and a customer storefront. It also features user registration with email validation.',
    link1: 'https://github.com/SimoneAiello97/Capstone',

      }
];


  return (

<Section id="works">
      <Container>

<Swiper

effect={'cards'}
grabCursor={true}
modules={[EffectCards]}
className="mySwiper"
    >
      {projects.map(project => (
        <SwiperSlide key = {project.id} className="slide">
            <img src={project.img} alt="" className='img-project' />
          <div className="slide-content">
            <p className="project-title">{project.title}</p>
            <p className="project-description">{project.description}</p>
            <a href={project.link1} className="project-link">GitHub Repository</a>
            </div>
        </SwiperSlide>
      ))}
    </Swiper>


</Container>
      </Section>
);
}

export default Works