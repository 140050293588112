import React, { useRef, useEffect } from 'react';
import Typed from 'typed.js';

const TypingEffect = () => {
  const typedRef = useRef(null);

  useEffect(() => {
    const options = {
      strings: ['Coder', 'Front-end Developer', 'Back-end Developer', 'Full-stack Developer'],
      loop: true,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 1000
    };

    typedRef.current = new Typed('.typed', options);

    return () => {
      if (typedRef.current) {
        typedRef.current.destroy();
      }
    };
  }, []);

  return (
    <p>I'm a <span className="typed"></span></p>
  );
}

export default TypingEffect;
