/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 scene.gltf --transform 
Files: scene.gltf [8.69KB] > scene-transformed.glb [29.7KB] (-242%)
Author: Diego G. (https://sketchfab.com/empty_mirror)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/voxel-web-development-50ad959d6c6b4799806c45bfa46ca550
Title: Voxel Web Development
*/

import React from 'react'
import { PerspectiveCamera, useGLTF, Text  } from '@react-three/drei'
//import { MeshStandardMaterial } from 'three';

export default function Model(props) {
  const { nodes, materials } = useGLTF('/scene-transformed.glb')
  //const customMaterial = new MeshStandardMaterial({ color: '#ff0000' });
  return (
    <group {...props} dispose={null}>
      <PerspectiveCamera makeDefault position={[10, 6, 12]} />
      <mesh geometry={nodes.Object_4.geometry} material={materials.palette} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.Object_6.geometry} material={materials['palette.001']} rotation={[Math.PI / 2, 0, 0]} />
      <Text
        position={[0, 2, 0]} // Posizione del testo
        rotation={[0, 1.5, 0]} // Rotazione del testo
        fontSize={2.3} // Dimensione del testo
        color="darkblue" // Colore del testo
      >
        Skill 
      </Text>
      <mesh geometry={nodes.Object_8.geometry} material={materials['palette.002']} rotation={[Math.PI / 2, 0, 0]} />
    
    </group>
  )
}

useGLTF.preload('/scene-transformed.glb')
