import React,  { Suspense, useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import Typed from 'typed.js';
/* import { useInView } from 'react-intersection-observer'; */
import { Canvas } from '@react-three/fiber';
import Model from './Scene';
import { OrbitControls } from '@react-three/drei';

const Section = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  overflow: hidden;
  @media only screen and (max-width: 768px){
    height:auto
  }
`;

const Container = styled.div`
display: flex;	
justify-content: center;
align-items: center;
width: 100%;
//margin-top:2.5rem;
height: 100%;
@media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:0
  }
`;
const Title = styled.div`
  width: 100%;
  display:block;
  color:white;
  font-size:18px;
  @media only screen and (max-width: 768px) {
  font-size:15px;
  }
`;
const Right = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  @media only screen and (max-width: 768px) {
    padding: 20px;
    justify-content: center;
  }
`;

const Left = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  height: 100%;
   @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const VsCode = styled.div`
  background-color: #001629;
  color: #01729e;
  border: 2px solid white;
  border-radius: 5px;
  width: 400px;
  height:220px;
  margin-bottom: 10px;
  padding: 10px;
  
  @media only screen and (max-width: 768px) {
    height: fit-content;
    width: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }
`;


/* const TypedListItem = ({ text, instant  }) => {
  const listItemRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [triggerRef, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    setIsVisible(inView);
  }, [inView]);

  useEffect(() => {
    if (isVisible) {
      const typedOptions = {
        strings: [text],
        typeSpeed: instant ? 0 : 250,
        showCursor: false,
      };

      const typedInstance = new Typed(listItemRef.current, typedOptions);

      return () => {
        typedInstance.destroy();
      };
    }
  }, [isVisible, text, instant]);

  return (
    <li className="typed-list-item" ref={triggerRef}>
      <div ref={listItemRef}></div>
    </li>
  );
}; */
const TypedListItem = ({ text }) => {
  const listItemRef = useRef(null);

  useEffect(() => {
    const typedOptions = {
      strings: [text],
      typeSpeed: 250,
      showCursor: false,
    };

    const typedInstance = new Typed(listItemRef.current, typedOptions);

    return () => {
      typedInstance.destroy();
    };
  }, [text]);

  return (
    <li className="typed-list-item">
      <div ref={listItemRef}></div>
    </li>
  );
};

const Skills = () => {
  const strings1 = ['HTML', 'CSS', 'JavaScript', 'Angular','React', 'TypeScript', 'Sass', 'Bootstrap'];
  const string2 = ['Java', 'PostGres', 'PostMan','EmailJs', 'SpringBoot', 'MySQL', 'NodeJS', 'ASP.NET MVC Core']
  const string3 = ['GitHub', 'Git', 'VS Code','Eclipse', 'ChatGPT', 'Firebase', 'Problem Solving', 'Communication Skills']
  const [rotationDirection, setRotationDirection] = useState(1);

  //provo questo per committare
  useEffect(() => {
    const timer = setTimeout(() => {
      setRotationDirection((prevDirection) => -prevDirection); // Inverti la direzione della rotazione
    }, 3000); // Cambia la direzione ogni 3 secondi

    return () => clearTimeout(timer);
  }, [rotationDirection]);
  return(
    <Section id="skills">
      <Container>

      <Left>
      <Canvas /* camera={{ position: [5, 5, 5], fov: 110 }} */>
      <fog attach="fog" args={['#ffffff', 10, 50]} />
            <Suspense fallback={null}>
              <ambientLight intensity={2} />
              <directionalLight position={[11, 1, 4]}
              castShadow // Abilita il lancio delle ombre
              shadow-mapSize-width={1024}
              shadow-mapSize-height={1024} />
              <Model />
              <OrbitControls enableZoom={false} autoRotate 
              autoRotateSpeed={0.5 * rotationDirection} 
           />
            </Suspense>
          </Canvas>
      </Left>

      <Right>
      <VsCode>
      <Title>🔴🟡🟢 Front-End</Title>
      <ol className="typed-list">
      {window.innerWidth > 768 ? (
  strings1.map((str, index) => (
    <TypedListItem key={index} text={str} />
  ))
) : (
  strings1.map((str, index) => (
    <li className="typed-list-item" key={index}>
      <div>{str}</div>
    </li>
  ))
)}
      </ol>
    
        </VsCode>
      <VsCode>
      <Title>🔴🟡🟢 Back-End</Title>
      <ol className="typed-list">
      {window.innerWidth > 768 ? (
  string2.map((str, index) => (
    <TypedListItem key={index} text={str} />
  ))
) : (
  string2.map((str, index) => (
    <li className="typed-list-item" key={index}>
      <div>{str}</div>
    </li>
  ))
)}
      </ol>
    
        </VsCode>
      <VsCode>
      <Title>🔴🟡🟢 Other</Title>
      <ol className="typed-list">
      {window.innerWidth > 768 ? (
  string3.map((str, index) => (
    <TypedListItem key={index} text={str} />
  ))
) : (
  string3.map((str, index) => (
    <li className="typed-list-item" key={index}>
      <div>{str}</div>
    </li>
  ))
)}
      </ol>
    
        </VsCode>
      </Right>
      </Container>
    </Section>

  )
}
export default Skills;
