import React, { Suspense } from 'react'
import { styled } from 'styled-components';
import Navbar from './Navbar';
import TypingEffect from './TypingEffect';
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Sphere, MeshDistortMaterial } from '@react-three/drei/core'
import DownloadButton from './DownloadButton';

const Section = styled.div`
height: 100vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //overflow: hidden;
  @media only screen and (max-width: 768px) {
    height: auto
  }
`;
const Container = styled.div`
  height: 100%;
  scroll-snap-align: center;
  width: 1400px;
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: 768px) and (max-width: 1399px){
    width:100%;
    justify-content: space-evenly;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    height: 100%
  }
`;

const Title = styled.h1`
  font-size: 74px;
  height:300px;
  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: 22px;
    height: auto;
  }
`;

const Subtitle = styled.h2`
  color: #ffff;
  font-size: 35px;
`;

const Desc = styled.p`
  font-size: 24px;
  font-style: italic;
  color: lightgray;
  @media only screen and (max-width: 768px) {
    padding: 20px;
    text-align: center;
  }
`;

const Left = styled.div`
flex: 2;
display: flex;
flex-direction: column;
justify-content: center;
align-items:space-between;

@media only screen and (max-width: 768px) {
  flex: 1;
  align-items: center;
}
`;

const Right = styled.div`
flex: 3;
position: relative;
@media only screen and (max-width: 768px) {
  flex: 1;
  width: 100%;
  min-height: 350px !important;
  align-items: center;
}
`;

const Img = styled.img`
  width: 800px;
  height: 600px;
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: animate 2s infinite ease alternate;
 
  @media only screen and (max-width: 768px) {
    width: 300px;
    height: 300px;
  }

  @keyframes animate {
    to {
      transform: translateY(20px);
    }
  }
`;

const Hero = () => {
  return (
    <Section>
      <Navbar/>
      <Container>
        <Left>
         <Subtitle>&lt;Hello world&gt;</Subtitle> 
          <Title>
           <TypingEffect/>
          </Title>
         <Desc>
         "Creatività e tecnologia: la mia formula per il successo digitale."         
          </Desc>
          <DownloadButton/>
        </Left>

        <Right>

        <Canvas >
          <Suspense fallback={null}>
            <OrbitControls enableZoom={false} autoRotate
            enableDamping={true}
            dampingFactor={1.3}
            />
            <ambientLight intensity={1} />
            <directionalLight position={[2, 2, 3]} />
            <group>
              <Sphere args={[1, 200, 200]} scale={2.4}>
                <MeshDistortMaterial
                  color="#023e8a"
                  attach="material"
                  distort={0.5}
                  speed={2}
                />
              </Sphere>
            </group>
          </Suspense>
        </Canvas>
        
          <Img src="../img/3D_Animation_Style_a_young_junior_developer_with_brown_hair_an_1-removebg-preview.png" />
        </Right>

      </Container>
    </Section>
  )
}

export default Hero